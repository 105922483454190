:root{
  --yellow: #efb528;
  --white: white;
  --black: #222525;
  --font-family: 'Libre Baskerville', serif;

  --breakpoint-sm: 360px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1129px;
  
}
body{
  background-image: url("../src/assets/background.png");
  background-position: 0px 0px;
  background-size: contain;
  background-attachment: scroll;
  box-sizing: border-box;
}

.header{
  display: flex;
  background-color: #222525;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.page-title{
  color: white;
  font-family: 'Libre Baskerville', serif;
  line-height: 1;
  font-weight: 400; 
  text-align: center;
}

#www-logo{
  width: 200px;
}
.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  max-width: 960px;
  margin: 16px 0;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

label{
  font-size: 14px;
  font-weight: bold;
  color: #ddd;
  margin-bottom: 8px;
}
.field{
  display: flex;
  flex-direction: column;
}
.form{
  display: grid;
  align-items: center;
  width: 100%;
  max-width: 960px;
  background-color: #222525;
  padding: 16px 8px;
  border-radius: 12px;
  margin: 16px;
  grid-template-columns: 1fr;
  align-items: flex-end;
}
.form > * {
  margin: 8px;
}
button{
  background-color: #efb528;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer; 
  flex-grow: 1;
  margin:  0 8px;
 }
button:hover{
  background-color: #dda724;
}
.buttons{
  display: flex;
}
input{
 text-indent: 24px;
    max-height: 45px;
    line-height: 1.4;
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #e4e8f0;
    border-radius: 6px;
    display: block;
    width: 100%;
    color: var(--black);
    background-color: var(--white);
    padding: 12px 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: ease-in;
    outline: none;
    overflow: auto;
    overflow-wrap: break-word;
    resize: none;
    transition-property: box-shadow,color,background-color;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.64,0,.35,1);
    overflow: hidden;
}
#payoff-bar{
  margin: 24px;
  width:100%;
  margin-bottom: 24px ;
  max-width: 580px;
}
.info, .note{
  max-width: 960px;
  text-align: center;
}

.note{
  margin-top: 24px;
  font-size: 8px;
  font-style: italic;
  text-align: right;
  align-self: flex-end;
}
@media screen and (min-width: 768px) {
  .form{
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    grid-template-rows: 1fr;
    grid-gap: 16px;

  }
}
